
<template>
  <div class="m-3">
    <header class="mx-3">
      <h3 class="title">Procesar Balances</h3>
    </header>
    <div class="card-body pt-0">
      <div class="table-responsive" style="height: 150px;">
        <table class="table table-bordered table-sm text-center">
          <thead>
            <tr>
              <th>Id</th>
              <th>Cliente</th>
              <th>Condicion IVA</th>
              <th>Tipo de comprobante</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" v-bind:key="item.id">
              <td>{{item.id}}</td>
              <td>{{item.customer}}</td>
              <td>{{item.vat_condition}}</td>
              <td>{{item.voucher_type}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button class="btn btn-primary btn-sm float-right my-2" @click="save">Procesar</button>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AlertService from "@/services/AlertService"
import BalanceService from "@/services/BalanceService"


export default {
  name: "PaymentComponent",
  props: {
    onClose: Object,
    onSave: Object,
    items: Array
  },
  methods: {
    async save() {
      const result = await AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer procesar?"
      );
      if (result.value) {
        this.isLoading = true;
        BalanceService.toBill(this.items.map(d => d.id))
          .then(() => {
            this.$store.commit("change", []);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Información`,
                text: `Operación Exitosa`,
                icon: "InfoIcon",
                variant: "info",
              },
            });
            this.onSave.handler();
            this.$emit("close");
          })
          .catch(error => {
            console.error(error);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: `Error al tratar de procesar`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
</style>
