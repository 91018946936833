import axios from 'axios';

export default class BalanceService {

    /**
     * @description get a element by id
     * @param { id } id point
     */
    static show(id) {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/' + id);
    }

    /*
     * @description update_password
     * @param { password,c_password  } values
     */
    static refundOfMoney({ id }, value) {
        return axios.put(process.env.VUE_APP_API + '/closure-operational/' + id + '/refund-of-money', value);
    }

    /*
     * @description update_password
     * @param { password,c_password  } values
     */
    static reversePayment({ id }, value) {
        return axios.put(process.env.VUE_APP_API + '/closure-operational/' + id + '/reverse-payment', value);
    }

    /**
     * 
     * @param {*} param0 
     * @returns 
     */
    static getPayment(closureId) {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/' + closureId + '/payments-mercado-pago');
    }

    /**
     * @description delete a element 
     * @param { id } id point 
     */
    static retry({ id }) {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/' + id + '/retry');
    }

    /**
     * @description delete a element 
     * @param { id } id point 
     */
    static destroy({ id }) {
        return axios.delete(process.env.VUE_APP_API + '/closure-operational/' + id);
    }

    /**
     * @description delete a element 
     * @param { id } id point 
     */
    static charged({ id }) {
        return axios.post(process.env.VUE_APP_API + '/closure-operational/' + id + '/charged');
    }

    /**
     * @description get a element by id
     * @param { id } id point
     */
    static
        export(params = {}) {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/export', {
            params: params,
            responseType: 'blob'
        });
    }

    /**
     * 
     * @param {*} id 
     */
    static pdf(id) {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/' + id + '/pdf', {
            responseType: 'blob'
        });
    }

    /**
     * 
     * @param {*} id 
     */
    static vouchers(id) {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/' + id + '/vouchers');
    }

    /**
     * 
     * @param {*} id 
     * @param {*} params 
     */
    static voucher(id, params) {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/' + id + '/voucher', { params });
    }

    /**
     * 
     */
    static toBill(items) {
        return axios.post(process.env.VUE_APP_API + '/closure-operational/to-bill', {
            ids: items
        });
    }

    /**
     * 
     * @returns 
     */
    static registerKeops(ids) {
        return axios.post(process.env.VUE_APP_API + '/closure-operational/register-keops', { ids });
    }

    /**
     * 
     * @returns 
     */
    static forInvoiced() {
        return axios.get(process.env.VUE_APP_API + '/closure-operational/register-keops');
    }
}