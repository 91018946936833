<template>
  <b-card>
    <b-tabs v-if="isAdmin">
      <b-tab title="TODAS" active>
        <BalanceAll />
      </b-tab>
      <b-tab title="PRE-PAGO">
        <BalanceAll type="pre-pago" />
      </b-tab>
      <b-tab title="POST-PAGO">
        <BalanceAll type="post-pago" />
      </b-tab>
    </b-tabs>
    <BalanceAll v-else />
  </b-card>
</template>

<script>
import BalanceAll from "./BalanceAll";
import UserService from "@/services/UserService"

export default {
  name: "BalancePage",
  components: { BalanceAll },
  data() {
    return {
      isAdmin: UserService.isAdminOrStaff()
    };
  }
};
</script>

<style lang="scss" scoped>
</style>
