import { render, staticRenderFns } from "./KeopComponent.vue?vue&type=template&id=20b4c636&scoped=true&"
import script from "./KeopComponent.vue?vue&type=script&lang=js&"
export * from "./KeopComponent.vue?vue&type=script&lang=js&"
import style0 from "./KeopComponent.vue?vue&type=style&index=0&id=20b4c636&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b4c636",
  null
  
)

export default component.exports