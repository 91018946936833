<template>
  <div class="m-3">
    <div class="card-modal pt-0">
      <div>
        <loading :active.sync="isLoading" :is-full-page="false"></loading>
        <b-tabs v-if="balance != null">
          <b-tab title="DATOS" active>
            <div class="card mx-8" v-if="balance.status === 'refund'">
              <div class="card-body p-3">
                <h2 class="title">Reintegro</h2>
                <RowComponent :title="'RAZON: '" :content="balance.reason" />
              </div>
            </div>

            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <h2 class="title">DATOS DEL BALANCE</h2>
                <RowComponent
                  :title="'BALANCE NUMERO:'"
                  :content="balance.id"
                />
                <RowComponent
                  :title="'TIENDA:'"
                  :content="balance.customer.brand"
                />
                <RowComponent
                  :title="'CONDICIÓN IVA:'"
                  :content="balance.vat_condition"
                />
                <RowComponent
                  :title="'TIPO DE COMPROBANTE:'"
                  :content="balance.voucher_type"
                />
                <RowComponent
                  :title="'NUMERO DE FACTURA:'"
                  :content="balance.invoice_number"
                />
                <RowComponent
                  :title="'MONTO TOTAL DE OPERACIONES:'"
                  :content="balance.cost_send"
                  type="amount"
                />
                <RowComponent
                  :title="'MONTO TOTAL DE COLECTA:'"
                  :content="balance.cost_operation"
                  type="amount"
                />
                <RowComponent
                  :title="'MONTO TOTAL DE CARGOS EXTRA:'"
                  :content="balance.cost_operation_extra_charge"
                  type="amount"
                />
                <RowComponent
                  :title="'TOTAL :'"
                  :content="balance.total"
                  type="amount"
                />
                <RowComponent :title="'ESTADO:'" :content="balance.status" />
              </div>
            </div>
            


            <div class="card mx-8" v-if="balance.transactions.length > 0">
              <div class="card-body p-3">
                <h2 class="title">OPERACIONES</h2>
                <div
                  class="table-responsive"
                  style="max-height: 200px; overflow: auto; overflow-y: auto"
                >
                  <table class="table">
                    <caption>
                      Lista de Operaciones
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th>FECHA</th>
                        <th>TRACKING</th>
                        <th>PRODUCTO</th>
                        <th>DIMENSION</th>
                        <th>PESO</th>
                        <th>MONTO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-bind:key="item.id"
                        v-for="item in balance.transactions"
                      >
                        <th scope="row">{{ item.id }}</th>
                        <td>{{ item.created_at }}</td>
                        <td>{{ item.tracking_deonics }}</td>
                        <th>{{ item.product }}</th>
                        <td>{{ item.dimensions }}</td>
                        <td>{{ item.weight }} KG</td>
                        <td>{{ item.amount | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              class="card mx-8 p-1"
              v-if="balance.charges && balance.charges.length > 0"
            >
              <div class="card-body p-3">
                <h2 class="title">CARGOS POR COLECTA</h2>
                <div
                  class="table-responsive"
                  style="max-height: 200px; overflow: auto; overflow-y: auto"
                >
                  <table class="table">
                    <caption>
                      Lista de cargos de colecta
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th>FECHA</th>
                        <th>SERVICIO</th>
                        <th>LOCALIDAD</th>
                        <th>MONTO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-bind:key="item.id" v-for="item in balance.charges">
                        <th scope="row">{{ item.id }}</th>
                        <td>{{ item.date_collection }}</td>
                        <td>{{ item.service.name }}</td>
                        <th>{{ item.location.location }}</th>
                        <td>{{ item.amount | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="card mx-8 p-1" v-if="balance.mercadoPago">
              <div class="card-body p-3">
                <h2 class="title">
                  MERCADO PAGO ({{ balance.mercadoPago.status }})
                </h2>
                <RowComponent
                  :title="'ID:'"
                  :content="balance.mercadoPago.id"
                />
              </div>
            </div>

            <div class="card mx-8" v-if="showErrorBil">
              <div class="card-body p-3">
                <h2 class="title">COMPROBANTES DEL CLIENTE</h2>
                <div
                  class="table-responsive"
                  style="max-height: 200px; overflow: auto; overflow-y: auto"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">COMPROBANTE</th>
                        <th>FECHA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-bind:key="item.id" v-for="item in vouchers">
                        <th scope="row">{{ item.comprobante }}</th>
                        <td>{{ item.fecha }}</td>
                        <td>
                          <b-button @click="onComprobante(item)" variant="info"
                            >descargar</b-button
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>




            <div class="card mx-8" v-if="balance.extraCharge.length > 0">

              <div class="card-body p-3">
                <h2 class="title">CARGOS EXTRA</h2>
                <div
                  class="table-responsive"
                  style="max-height: 200px; overflow: auto; overflow-y: auto"
                >
                  <table class="table">

                    <caption>
                      Lista de cargos extra
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th>TITULO</th>
                        <th>DETALLE</th>
                        <th>MONTO</th>
                      </tr>
                    </thead>



                    <tbody>
                      <tr
                        v-bind:key="item.id"
                        v-for="item in balance.extraCharge"
                      >
                        <th scope="row">{{ item.id }}</th>
                        <td>{{ item.title }}</td>
                        <th>{{ item.body }}</th>
                        <td>{{ item.price | currency }}</td>
                      </tr>
                    </tbody>



                  </table>
                </div>
              </div>
            </div>






          </b-tab>
          <b-tab title="MERCADO PAGO" v-if="mercadoPago && isAdmin">
            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <h2 class="title my-2">DATOS DEL PAGO</h2>
                <RowComponent
                  :title="'ESTADO:'"
                  :content="mercadoPago.status"
                />
                <RowComponent
                  :title="'DETALLE DEL ESTADO:'"
                  :content="mercadoPago.status_detail"
                />

                <div class="mt-2" v-if="mercadoPago.refunds.length > 0">
                  <h2 class="title my-2">REINTEGROS</h2>

                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">REINTEGRO</th>
                        <th scope="col">ESTADO</th>
                        <th scope="col">FECHA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in mercadoPago.refunds" :key="item.id">
                        <th scope="row">{{ item.id }}</th>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.date_created }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p class="mt-4">
                  <strong> Notas para Reembolso</strong><br />
                  <br />
                  <strong>Fecha límite de reembolso:</strong> es posible
                  devolver un pago dentro de los 180 días posteriores a la fecha
                  de aprobación. <br />
                  <strong>Saldo de la cuenta:</strong> debes tener suficiente
                  saldo disponible en tu cuenta para reembolsar el monto, de lo
                  contrario no se realizará la transacción.<br />
                  <strong>Forma de pago:</strong> para pagos con tarjeta de
                  crédito, el monto se reembolsará directamente en la factura.
                  Para otros métodos de pago como Pix, por ejemplo, el monto se
                  devolverá directamente a la cuenta del pagador.
                </p>
              </div>
            </div>
          </b-tab>
          <b-tab title="PAYLOAD KEOPS" v-if="balance.payload_keops && isAdmin">
            <div class="m-4">
              <vue-json-pretty
                :deep="4"
                :data="balance.payload_keops"
              ></vue-json-pretty>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <footer class>
      <div class="row">
        <div class="col-12 pt-3">
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="outline-primary"
            @click="onClose"
            >Cerrar</b-button
          >

          <b-button
            v-if="isAdmin && balance && balance.can_be_invoiced"
            size="sm"
            class="mr-1 float-right"
            variant="info"
            @click="onRegisterKeops"
          >
            Registrar en Keops
          </b-button>

          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onPdf"
            v-if="
              isAdmin &&
              balance &&
              (balance.status == 'FACTURADA' || balance.status == 'PAGADA') &&
              showErrorBil == false
            "
            >PDF</b-button
          >

          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="retry"
            v-if="
              isAdmin && balance && balance.status == 'ERROR DE FACTURACION'
            "
            >REINTENTAR FACTURAR</b-button
          >

          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onCharged(balance)"
            v-if="
              isAdmin &&
              balance &&
              balance.status == 'FACTURADA' &&
              balance.type === 'post-pago'
            "
            >Cobrado</b-button
          >

          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onCharged(balance)"
            v-if="
              isAdmin &&
              balance &&
              balance.status == 'PENDIENTE' &&
              balance.type === 'post-pago' &&
              oldBalance
            "
            >Cobrado</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserService from "@/services/UserService";
import AlertService from "@/services/AlertService";
import BalanceService from "@/services/BalanceService";

import axios from "axios";
import moment from "moment";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "BalanceView",
  components: { VueJsonPretty },
  props: {
    item: Object,
    onRetry: Object,
  },
  data() {
    return {
      vouchers: [],
      showErrorBil: false,
      balance: null,
      mercadoPago: null,
      isLoading: false,
      isAdmin: UserService.isAdminOrStaff(),
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    /**
     *
     */
    async onCharged(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de querer marcar como cobrada el item " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        BalanceService.charged(item)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.item.status = "PAGADO";
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de procesar la solicitud`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    show() {
      this.isLoading = true;
      BalanceService.show(this.item.id)
        .then((response) => {
          this.isLoading = false;
          if (!axios.isCancel(response)) {
            const { data } = response;
            this.balance = data.data;
          }

          /**
           *
           * Load data MercadoPago
           */
          if (
            this.balance.type == "pre-pago" &&
            ( this.balance.status == "PAGADA" || this.balance.status == 'refund' )
          ) {
            this.loadMercadoPago();
          }
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },

    /**
     *
     */
    loadMercadoPago() {
      this.isLoading = true;
      BalanceService.getPayment(this.item.id)
        .then((response) => {
          const { data } = response;
          this.mercadoPago = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },

    /**
     *
     */
    onPdf() {
      this.isLoading = true;
      BalanceService.pdf(this.item.id)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const fileName = "comprobante.pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.showErrorBil = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Facturación`,
              text: `La Factura aun no ha sido emitida. Por Favor reintentar en algunas horas. Gracias`,
              icon: "InfoIcon",
              variant: "info",
            },
          });
          BalanceService.vouchers(this.item.id)
            .then((_response) => {
              this.vouchers = _response.data.data.data;
              this.showErrorBil = true;
            })
            .catch((_error) => {
              console.error(_error);
            });
        });
    },

    /**
     *
     */
    async retry() {
      this.isLoading = true;
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de reintar facturar ??  " + this.item.id
      );
      if (result.value) {
        this.isLoading = true;
        BalanceService.retry(this.item)
          .then(() => {
            AlertService.info();
            this.onRetry.handler();
            this.show();
          })
          .catch((thrown) => {
            this.isLoading = false;
            console.error(thrown);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de procesar la solicituds`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    onComprobante(item) {
      this.isLoading = true;
      BalanceService.voucher(this.item.id, {
        id_tcomp: item.id_tcomp,
        id_lcomp: item.id_lcomp,
        id_pcomp: item.id_pcomp,
        id_ncomp: item.id_ncomp,
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const fileName = "comprobante.pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    /**
     *
     */
    onClose() {
      this.$emit("close");
    },

    /**
     *
     */
    async onRegisterKeops() {
      const questions = "Estas seguro de querer registrar en Keops ? ";
      const result = await AlertService.question("¿Estás seguro?", questions);
      if (result.value !== undefined) {
        this.isLoading = true;
        BalanceService.registerKeops([this.balance.id])
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.show();
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
            this.$bvToast.toast(
              "ocurrio un error al intentar registrar o actualizar en keops",
              {
                title: "keops",
                autoHideDelay: 3000,
                variant: "error",
                appendToast: false,
              }
            );
          });
      }
    },
  },
  computed: {
    oldBalance() {
      const old = new moment("26/10/2020", "DD/MM/YYYY");
      return new moment(this.balance.created_at, "YYYY-MM-DD").isSameOrBefore(
        old
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
