<template>
  <div class="m-3">
    <header class="mx-4">
      <h3 class="title">Filtrar Balance</h3>
    </header>

    <div class="card-body pt-0 py-3">
      <b-form-group label="Año">
        <b-form-select v-model="year" :options="years"></b-form-select>
      </b-form-group>

      <b-form-group label="Mes">
        <b-form-select v-model="month" :options="months"></b-form-select>
      </b-form-group>

      <div>
        <button class="btn btn-primary btn-sm float-right my-2" @click="save">
          Generar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BalanceXls",
  props: {
    onClose: Object,
    onSave: Object,
  },
  data() {
    return {
      years: [
        { value: "2019", text: "2019" },
        { value: "2020", text: "2020" },
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
      ],
      months: [
        { value: "01", text: "Enero" },
        { value: "02", text: "Febrero" },
        { value: "03", text: "Marzo" },
        { value: "04", text: "Abril" },
        { value: "05", text: "Mayo" },
        { value: "06", text: "Junio" },
        { value: "07", text: "Julio" },
        { value: "08", text: "Agosto" },
        { value: "09", text: "Septiembre" },
        { value: "10", text: "Octubre" },
        { value: "11", text: "Noviembre" },
        { value: "12", text: "Diciembre" },
      ],
      year: moment().year(),
      month: moment().month(),
    };
  },
  methods: {
    async save() {
      this.isLoading = true;
      this.onSave.handler({
        year: this.year,
        month: this.month,
      });
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
