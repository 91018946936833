<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <header class="my-1">
        <h3 class="title">Registrar en Keops</h3>
      </header>
      <div class="table-responsive">
        <b-button variant="primary" :disabled="!activeToStore" @click="save">
          Procesar
        </b-button>

        <table class="table table-bordered table-sm text-center mt-1">
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>Cliente</th>
              <th>Condicion IVA</th>
              <th>Tipo de comprobante</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" v-bind:key="item.id">
              <td>
                <b-form-checkbox v-model="item.select"> </b-form-checkbox>
              </td>
              <td>{{ item.id }}</td>
              <td>{{ item.customer }}</td>
              <td>{{ item.vat_condition }}</td>
              <td>{{ item.voucher_type }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BalanceService from "@/services/BalanceService";
import AlertService from "@/services/BalanceService";

export default {
  name: "KeopComponent",
  props: {
    onClose: Object,
    onSave: Object,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    save() {
      const items = this.items.filter((d) => d.select).map((d) => d.id);
      BalanceService.registerKeops(items)
        .then(() => {
          AlertService.info();
          this.onSave.handler();
          this.$emit("close");
        })
        .catch((error) => {
          console.error(error);
          this.$bvToast.toast(
            "ocurrio un error al intentar registrar o actualizar en keops",
            {
              title: "keops",
              autoHideDelay: 3000,
              variant: "error",
              appendToast: false,
            }
          );
        });
    },
  },
  computed: {
    activeToStore: function () {
      return this.items.find((d) => d.select == true);
    },
  },
  mounted() {
    BalanceService.forInvoiced().then((response) => {
      this.items = response.data.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 420px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
